import { TextCreativeApi } from '@api';
import { textCreativeTypes as actionTypes } from './types';

export const fetchTextCreatives = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  TextCreativeApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const createTextCreative = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  TextCreativeApi.create({ textCreative: params })
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'prepend',
      });

      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};

export const updateTextCreative = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  TextCreativeApi.update(id, { textCreative: params })
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};

export const bulkUpdateTextCreative = (ids, params) => (dispatch) => {
  dispatch({
    type: actionTypes.BULK_UPDATE.LOADING,
  });

  TextCreativeApi.bulkUpdate({ ids, textCreative: params })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: actionTypes.LIST.HYDRATE,
          payload: response,
          method: 'merge',
        });
      }

      dispatch({
        type: actionTypes.BULK_UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};

import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import { UserApi } from '@api';
import { setAuthToken, getAuthToken, clearAuthToken } from '@dotone/react-ui-core/utils';
import { authTypes as actionTypes } from './types';

export const authenticate = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LOADING,
  });

  const { redirectTo, ...data } = params;

  UserApi.authenticate(data)
    .then((response) => {
      setAuthToken(response.token);
      dispatch({
        type: actionTypes.SUCCESS,
        payload: response,
        redirectTo,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FAILURE,
        redirectTo,
        ...error.data,
      });
    });
};

export const deauthenticate = () => (dispatch) => {
  clearAuthToken();
  dispatch({
    type: actionTypes.DEAUTH,
  });
  Sentry.setUser(null);
};

export const authenticateViaToken = (params, redirectTo) => (dispatch) => {
  dispatch(deauthenticate());

  dispatch({
    type: actionTypes.LOADING,
  });

  UserApi.authenticateViaToken(params)
    .then((response) => {
      setAuthToken(response.token);
      dispatch({
        type: actionTypes.SUCCESS,
        payload: response,
        redirectTo,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FAILURE,
        redirectTo,
        ...error.data,
      });
    });
};

export const authenticateViaProvider = (provider, params) => (dispatch) => {
  dispatch({
    type: actionTypes.LOADING,
  });

  const { redirectTo, ...data } = params;

  UserApi.authenticateViaProvider(provider, data)
    .then((response) => {
      setAuthToken(response.token);
      dispatch({
        type: actionTypes.SUCCESS,
        payload: response,
        redirectTo,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FAILURE,
        redirectTo,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const registerViaInstagramProvider = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LOADING,
  });

  UserApi.authenticateViaProvider('instagram', params)
    .then((response) => {
      dispatch({
        type: actionTypes.INSTAGRAM,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const reauthenticate =
  (params = {}) =>
  (dispatch) => {
    const token = getAuthToken('network');
    if (token) {
      UserApi.refreshToken(params)
        .then((response) => {
          setAuthToken(response.token);
          dispatch({
            type: actionTypes.REAUTH,
            token: response.token,
            ...params,
          });
        })
        .catch(() => {
          dispatch(deauthenticate());
        });
    } else {
      dispatch({
        type: actionTypes.REAUTH,
        ...params,
      });
      Router.push('/advertisers/login');
    }
  };

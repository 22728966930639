import { ImageCreativeApi } from '@api';
import { imageCreativeTypes as actionTypes } from './types';

export const fetchImageCreatives = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  ImageCreativeApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const createImageCreative =
  ({ images, ...params }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.CREATE.LOADING,
    });

    const requests = images.map((image) =>
      ImageCreativeApi.create({ imageCreative: { ...params, ...image } })
    );

    Promise.all(requests)
      .then((responses) => {
        const data = responses.map((response) => response.data);
        dispatch({
          type: actionTypes.LIST.HYDRATE,
          payload: { data },
          method: 'merge',
        });

        dispatch({
          type: actionTypes.CREATE.SUCCESS,
          payload: responses[0],
          message: 'notify:general.save.success',
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CREATE.FAILURE,
          message: `notify:general.save.error.${error.status}`,
        });
      });
  };

export const updateImageCreative = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  ImageCreativeApi.update(id, { imageCreative: params })
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};

export const bulkUpdateImageCreative = (ids, params) => (dispatch) => {
  dispatch({
    type: actionTypes.BULK_UPDATE.LOADING,
  });

  ImageCreativeApi.bulkUpdate({ ids, imageCreative: params })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: actionTypes.LIST.HYDRATE,
          payload: response,
          method: 'merge',
        });
      }

      dispatch({
        type: actionTypes.BULK_UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};
